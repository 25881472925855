import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Button, Typography } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserContext";

const { Header } = Layout;

const AppHeader = ({ collapsed, onClick }) => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const { Title } = Typography;
  return (
    <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
      {collapsed ? (
        <MenuUnfoldOutlined className="trigger" onClick={onClick} />
      ) : (
        <MenuFoldOutlined className="trigger" onClick={onClick} />
      )}
      <div
        style={{ float: "right", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: 40 }}

      >
        <Title style={{ marginRight: 35 }} level={5}>{`${user?.name} ${user?.lastname}`}</Title>
        <Button
          type="primary"
          danger
          icon={<LogoutOutlined />}
          // style={{ float: "right", marginTop: 15, marginRight: 20 }}
          onClick={() => {
            localStorage.removeItem("adtoken");
            localStorage.removeItem("adrefreshToken");
            localStorage.removeItem("manUser");
            setUser(null);
            history.push("/login");
          }}
        >
          გასვლა
        </Button>
      </div>

    </Header>
  );
};

export default AppHeader;
