import React, { useState } from "react";
import { Layout } from "antd";

import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import "./Layout.css";

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <AppSidebar
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      />
      <Layout className="site-layout">
        <AppHeader
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
