import React, { lazy, useContext } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { UserContext } from "./context/UserContext";

import "./App.css";
import AppLayout from "./components/Layout";

const Dashboard = lazy(() => import("./container/Dashboard"));
const News = lazy(() => import("./container/News"));
const SubCategories = lazy(() => import("./container/SubCategories"));
const Events = lazy(() => import("./container/Events"));
const Users = lazy(() => import("./container/Users"));
const Galleries = lazy(() => import("./container/Galleries"));
const addEvent = lazy(() => import("./container/addEvent"));
const EditEvent = lazy(() => import("./container/EditEvent"));
const AddImages = lazy(() => import("./container/AddImages"));
const Applicants = lazy(() => import("./container/Applicants"));
const Public = lazy(() => import("./container/Public"));

const Mail = lazy(() => import("./container/Mail"));
const Sms = lazy(() => import("./container/Sms"));
const Commission = lazy(() => import("./container/Commission"));

const CommEvents = lazy(() => import("./container/commision/Events"));
const CommApplicants = lazy(() => import("./container/commision/Applicants"));
// const CommApplicants = lazy(() => import("./container/CommApplicants"));

const Blog = lazy(() => import("./container/Blog"));
const Approve = lazy(() => import("./container/Approve"));
const Logs = lazy(() => import("./container/Logs"));
const ApplicantManage = lazy(() => import("./container/super/ApplicantManage"));

const App = () => {
  const { user } = useContext(UserContext);

  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       user ? <Component {...props} /> : <Redirect to="/login" />
  //     }
  //   />
  // );

  // const Protected = () => <h3>Protected</h3>;

  return (
    <Router>
      <AppLayout>
        <Switch>
          {user && user.role === "admin" && (
            <Route exact path="/" component={Dashboard} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/dashboard" component={Dashboard} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/subCategories" component={SubCategories} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/events" component={Events} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/addevent" component={addEvent} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/addImages/:gallery" component={AddImages} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/editEvent/:event" component={EditEvent} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/applicants/:event" component={Applicants} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/users" component={Users} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/galleries" component={Galleries} />
          )}

          {user && user.role === "admin" && (
            <Route exact path="/app/news" component={News} />
          )}

          {user && user.role === "admin" && (
            <Route exact path="/app/blog" component={Blog} />
          )}

          {user && user.role === "admin" && (
            <Route exact path="/app/mail" component={Mail} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/sms" component={Sms} />
          )}

          {user && user.role === "admin" && (
            <Route exact path="/app/public" component={Public} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/approve" component={Approve} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/logs" component={Logs} />
          )}
          {user && user.role === "admin" && (
            <Route exact path="/app/commission" component={Commission} />
          )}

          {user && user.role === "commission" && (
            <Route exact path="/app/commEvents" component={CommEvents} />
          )}
          {user && user.role === "commission" && (
            <Route exact path="/" component={CommEvents} />
          )}
          {user && user.role === "commission" && (
            <Route
              exact
              path="/app/commApplicants/:event"
              component={CommApplicants}
            />
          )}
          {user && user.role === "super" && (
            <Route exact path="/" component={ApplicantManage} />
          )}
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default App;
