import React, { lazy, useMemo, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactDOM from "react-dom";

import moment from "moment";

import App from "./App";

import { UserContext } from "./context/UserContext";

import "antd/dist/antd.css";
import "moment/locale/ka";
import { Spin } from "antd";
import ApolloProviderWrapper from "./utils/ApolloProviderWrapper";
moment.locale("ka");

const Login = lazy(() => import("./container/Login"));


const Root = () => {
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  const token = localStorage.getItem("adtoken");
  const manUser = localStorage.getItem("manUser");

  useEffect(() => {
    if (token && token !== null) {
      try {
        setUser(JSON.parse(manUser));
      } catch (error) {
        localStorage.removeItem("manUser");
      }
    }
  }, []);
  return (
    <UserContext.Provider value={value}>
      <ApolloProviderWrapper>
        <Router>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            }
          >
            <Switch>
              {!user && <Redirect from="/" to="/login" exact />}
              {user && <Redirect from="/login" to="/" exact />}
              <Route exact path="/login" component={Login} />

              <App></App>
            </Switch>
          </Suspense>
        </Router>
      </ApolloProviderWrapper>
    </UserContext.Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
